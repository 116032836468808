import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {apiUrl} from "../utils/url";


const appApi = createApi({
    reducerPath: "appApi",
    baseQuery: fetchBaseQuery({
        baseUrl: apiUrl,
    }),

    endpoints: (builder) => ({

        signupUser: builder.mutation({
            query: (user) => ({
                url: `${apiUrl}/auth/register`,
                method: "POST",
                body: user,
            }),
        }),


        loginUser: builder.mutation({
            query: (user) => ({
                url: `${apiUrl}/auth/login`,
                method: "POST",
                body: user,
            }),
        }),


        logoutUser: builder.mutation({
            query: (payload) => ({
                url: `${apiUrl}/logout`,
                method: "DELETE",
                body: payload,
            }),
        }),
    }),
});

export const {useSignupUserMutation, useLoginUserMutation, useLogoutUserMutation} = appApi;

export default appApi;
