import React, {useContext, useState} from "react";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useLoginUserMutation} from "../services/appApi";
import {Link, useNavigate} from "react-router-dom";
import "./Login.css";
import {AppContext} from "../context/appContext";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const {socket} = useContext(AppContext);
    const [loginUser, {isLoading, error}] = useLoginUserMutation();

    function handleLogin(e) {
        e.preventDefault();
        // Logika logowania:
        loginUser({email, password}).then(({data}) => {
            if (data) {

                socket.emit("new-user");
                navigate("/chat");


            }

        });
    }

    return (
        <Container>
            <Row>
                <Col md={5} className="login__bg"></Col>
                <Col md={7} className="d-flex align-items-center justify-content-center flex-direction-column">
                    <Form style={{width: "80%", maxWidth: 500}} onSubmit={handleLogin}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            {error && <p className="alert alert-danger">{error.data}</p>}
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Wprowadz e-mail"
                                          onChange={(e) => setEmail(e.target.value)} value={email} required/>
                            <Form.Text className="text-muted">Nigdy nie podawaj swoich danych do logowania komuś innemu
                                !</Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Wprowadz hasło"
                                          onChange={(e) => setPassword(e.target.value)} value={password} required/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {isLoading ? <Spinner animation="grow"/> : "Zaloguj się"}
                        </Button>
                        <div className="py-4">
                            <p className="text-center">
                                Nie masz konta ? <Link to="/signup">Załóż konto</Link>
                            </p>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

